.offcanvas {
    
    &.bg-secondary {

        .offcanvas-body {
                
            a.nav-link {
                font-size: 1.25rem;
                margin-top: 0;
                margin-bottom: 0.5rem;
                font-family: "Acumin Variable Concept", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-weight: 500;
                line-height: 1.2;
                --dcscc-text-opacity: 1;
                color: rgba(var(--dcscc-light-rgb), var(--dcscc-text-opacity)) !important;

                &:hover {
                    --dcscc-text-opacity: 1;
                    color: rgba(var(--dcscc-primary-rgb), var(--dcscc-text-opacity)) !important;
                }
            }
        }
    }
}
